.sales-data--container {
  width: 100vw;
  display: block;
  max-height: calc(100vh - 30px);
  overflow-y: scroll;
  margin-top: 10px;
}
.sales-data--holder {
  margin-top: 50px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
}
.sales-data--profile--holder {
  display: flex;
  padding-bottom: 10px;
  width: calc(100vw - 60px);
  border-bottom: 1px solid gray;
}
.sales-data--title-holder {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.sales-data--filler {
  flex: auto;
}
.sales-data--profile {
  display: flex;
  justify-content: left;
}
.sales-data--action-buttons {
  margin-top: -5px;
  margin-left: auto;
}
.sales-data--gallery {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 10px;
}
.sales-data--toggle-holder {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
}

.sales-data--title-gender {
  font-size: 1.2em;
  text-align: center;
}

.sales-data--title-name {
  font-size: 1.8em;
  display: inline-block;
}
.sales-data--title-born-year {
  font-size: 1.2em;
  text-align: center;
}
.sales-data--profile-image {
  width: 60px;
  height: 60px;
  margin: 0;
  border: 1px solid black;
}
.empty--holder {
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
}
.empty--info {
  margin: auto;
}
.empty--illustration {
  display: flex;
  justify-content: center;
}
.empty--illustration svg {
  padding: 10px;
  border-radius: 100%;
  border: 1px solid rgb(130, 129, 151);

  background: linear-gradient(180deg, #e4e4e7 0%, rgba(208, 232, 240, 0) 100%);
}
.empty--title {
  margin-top: 10px;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .sales-data--profile--holder {
    flex-direction: column;
    padding-bottom: 45px;
  }
  .sales-data--holder {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
  }
  .sales-data--profile-image {
    width: 150px;
    height: 150px;
    margin: auto;
  }
  .sales-data--title-holder {
    flex-direction: column;
    padding-top: 10px;
    margin-bottom: 25px;
  }
  .sales-data--profile {
    justify-content: center;
    flex-direction: column;
  }
  .sales-data--gallery {
    margin-top: 10px;
  }
}
