#newGeneralTaskTypeWindowReact {
  box-shadow: 2px 3px 5px #999;
  z-index: 100 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

#newGeneralTaskTypeReact {
  height: 42px;
  background-color: #292a3c !important;
  color: #ffffff !important;
}

#generalTaskPopupCopyDays {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 600px;
  margin: 5px;
}

#generalTaskPopupCopyDays td {
  max-width: 120px;
  min-width: 120px;
  flex: 1;
}
#generalTaskPopupCopyDays td div {
  gap: 5px;
}

#cancelNewGeneralTaskTypeReact {
  height: 36px;
  float: left;
  margin-top: 2px;
  margin-left: 5px;
  filter: invert(100%);
  cursor: pointer;
}

#OkButtonReact {
  height: 30px;
  float: right;
  margin-top: 5px;
  margin-right: 8px;
  filter: invert(100%);
  cursor: pointer;
}

#inputNewGeneralTaskTypeReact {
  width: -webkit-fill-available;
  text-align: left !important;
}

@media screen and (max-width: 814px) {
  #generalTaskPopupCopyDays {
    justify-content: center;
  }
}
