.basic-button {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.basic-button > svg {
  width: 25px;
  height: 25px;
}
