.hvpAppLogo {
  height: 60px;
  margin-left: 30px;
  margin-top: 2px;
  filter: invert(1);
}

.hvpRight {
  float: right;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.hvpIconRignt {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  margin-left: 5px;
}

.hvmLogo {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-color: white;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
  border: 3px solid black;
  z-index: 2;
}

.hvmEditIcon {
  margin-left: 20px;
  margin-right: -50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.hvpButtonsDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.hvpButton {
  position: relative;
  color: white;
  background-color: #292a3c;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 5px;
  text-align: center;
  cursor: pointer;
}

.hvpButton > span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hvpContent {
  margin-top: 60px;
  margin-left: 10px;
  margin-right: 10px;
}

.hvpImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  margin: auto;
  cursor: pointer;
}

.hvpFloatHalf {
  width: calc(50% - 6px);
  padding-left: 3px;
  padding-right: 3px;
}

.hvpFloatQuard {
  min-width: calc(25% - 6px);
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 0.5em;
}

.hvpEditButton {
  width: calc(50% - 6px) !important;
  margin: 3px;
  margin-bottom: 8px;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hvpEditButton img {
  height: 20px;
  margin-right: 5px;
}

.hvmpaddingbottom {
  padding-bottom: 80px;
}

.creatableSelect .css-b8ldur-Input {
  margin: 0 !important;
  padding: 0 !important;
}

.creatableSelect .ui-input-text {
  margin-left: 2px !important;
  margin-right: 2px !important;
  margin: 0;
  border-width: 0;
}
