.ppContent {
    margin-top: 65px;
    padding: 10px;
}

.horseTypeTable {
    border-collapse: collapse;
    width: 80%;
    text-align: center;
}

    .horseTypeTable td, th {
        border: 1px solid gray;
    }

    .horseTypeTable tr:nth-last-child(-n+2) > td {
        text-align: center;
        border: initial;
    }
