.traningDayTable {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.traningDayTable td {
  border-right: 1px solid gray;
}

.trainingCellContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.trainingCell {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.trainingCellName {
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
}

.trainingCellNameSmall {
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
}

.trainingCellNotDone {
  color: red;
}
.trainingCellNotDoneTimeSet {
  color: green;
}

.trainingCellDone {
  text-decoration: line-through;
  text-shadow: none;
}

.trainingCellTime {
  font-size: 0.6em;
  text-align: center;
  margin-bottom: -4px;
}

.trainingCellNotes {
  font-size: 0.6em;
  text-align: center;
  margin-top: -4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
