.pdfDocument {
    padding-top: 40px;
    overflow: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.pdfPages {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}


    .pdfPages .pdfArrowIcon {
        height: 40px;
        width: 40px;
        cursor: pointer;
    }
