@media screen and (max-width: 500px) {
  .otherTasksViewTable {
    min-width: 100vw;
  }
  .otherTasksViewTable tr td {
    max-width: 100vw !important;
  }

  .otherTasksViewHolderWrapper {
    flex-direction: column !important;
    justify-content: center;
    gap: 12px;
  }
  .otherTasksViewHolderScrollArea {
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(100vh - 175px);
  }
}
@media screen and (min-width: 500px) {
  .otherTasksViewHolderWrapper {
    all: unset !important;
  }
  .otherTasksViewHolderScrollArea {
    overflow-y: auto;
    max-height: calc(100vh - 175px);
  }
  .otherTasksViewHolder {
    display: flex;
    justify-content: flex-start;
  }
  .otherTasksViewTable tr td {
    max-width: 340px !important;
  }
}
.otherTasksViewTable--task-name {
  overflow-wrap: break-word;
}

.otherTasksViewTable,
.otherTasksViewTable tr,
.otherTasksViewTable tr td {
  border: none !important;
}

.otherTasksViewTable,
.otherTasksViewTable tr {
  border: 1px solid gray !important;
}
.otherTasksViewTable--last-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.otherTasksViewTable--last-row span {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
}
.otherTasksViewTable--last-row--reorder {
  margin-top: 3px;
}
.otherTasksViewTable--reorder-column {
  width: 30px !important;
}
