.collapsible {
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border-radius: 10px;
  border-color: black;
  text-align: left;
  outline: none;
  background: bottom;
}

.collapsible-content {
  padding: 0 18px;
  display: block;
  overflow: hidden;
}

.changeLicensePopup {
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  border-color: #111111;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.changeLicenseClose {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 40px;
  height: 40px;
}

.crImage {
  width: 50%;
  margin-left: 25%;
}

.reset-password--input-holder {
  margin-top: 5px;
}

.reset-password--input-holder input {
  -webkit-text-security: disc;
  text-security: disc;
}

.sharedHorses--column-holder {
  display: flex;
  align-items: center;
}

.sharedHorses--column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 5px;

  align-items: center;
  justify-items: center;
}

.sharedHorses--column-select {
  flex: auto;
  margin-bottom: 5px;
  max-width: 150px;
}
