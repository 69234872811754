.card__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2147483647; /*maximum z-index*/
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  justify-items: center;
}
.card {
  border-radius: 0.75rem;
  background-color: #fff;
  overflow: hidden;
  flex: 1;
  margin: auto;
  margin-left: 15px;
  margin-right: 15px;
}
.info__padding {
  padding: 20px 40px 10px 40px;
}

.button__group button {
  color: #007aff;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 18px 10px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid rgb(220, 224, 226);
}
.button__group:after {
  content: "";
  clear: both;
  display: table;
}
.button__group button:not(:last-child) {
  border-left: none;
}
.button__group button:hover {
  background-color: rgb(250, 250, 250);
}
.button__group button:active {
  background-color: rgb(245, 245, 245);
}
