.ctContent {
    margin-top: 45px;
    padding: 10px;
}


.ctTable {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

    .ctTable th, .ctTable td {
        border: 1px solid gray;
        text-align: center;
    }

    .ctTable tr:last-child > td {
        text-align: center;
        border: initial;
    }
