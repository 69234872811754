.XrayTable {
    margin-top: 80px;
    width: 100%;
    border-collapse: collapse;
}

    .XrayTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .XrayTable th {
        border: 1px solid gray;
    }

    .XrayTable tr > th {
        background-color: #292A3C;
        color: #FFFFFF;
        text-shadow: 0 0 0;
    }
