.ppContent {
    margin-top: 45px;
    padding: 10px;
}

.dewormingTypesTable {
    border-collapse: collapse;
    width: 80%;
    text-align: center;
}

    .dewormingTypesTable tr {
        border: 1px solid gray;
    }

    .dewormingTypesTable td {
        border: 1px solid gray;
    }
