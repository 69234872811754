#vaccinationTable {
    margin-top: 65px;
}

.VaccTable {
    width: 100%;
    border-collapse: collapse;
}

    .VaccTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .VaccTable th {
        border: 1px solid gray;
    }

    .VaccTable tr > th {
        text-shadow: 0 0 0;
    }
