.sharedWithMeRow {
    text-align: left !important;
    height: 20px !important;
}

.tableWeekView {
    width: calc(100vw - 20px);
    border-collapse: collapse;
    table-layout: fixed;
}

.tableWeekViewMobile {
    width: calc(100vw);
}

.tableWeekView thead td {
    font-size: small;
}

.tableWeekView > tbody > tr > td,
.tableWeekView > thead > tr > td {
    border-right: 4px solid gray;
    border-bottom: 1px solid gray;
    text-align: center;
    height: 50px;
    padding: 0px;
}

.feedHeader {
    width: 98%;
    height: 98%;
    margin-top: 2%;
    text-align: center;
}

.scrollableFeed {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}

.scrollableMobileFeed {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}

.feedinput {
    width: 98%;
    height: 98%;
    margin-top: 2%;
}

.feedDeleteHeaderDiv {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    border: 1px solid gray;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px;
    border-radius: 10px;
    background-color: lightgray;
}

.feedDeleteTitle {
    text-align: center;
    padding: 7px;
    border-bottom: 1px solid black;
}

.feedDeleteBottom {
    text-align: center;
    padding: 7px;
    font-weight: bold;
    cursor: pointer;
}

.feedDeleteItem {
    padding: 10px;
    border-bottom: 1px solid black;
    background-color: white;
    font-size: 1em;
    cursor: pointer;
}