#reportTableReact {
    overflow-x: auto;
}

.reportTable {
    width: max-content;
    border-collapse: collapse;
}

    .reportTable th {
        border: 1px solid gray;
    }

    .reportTable tr {
        border: 1px solid gray;
    }

    .reportTable td {
        border: 1px solid gray;
    }

.reportButton {
    height: 46px;
}

.arrowUp {
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-transform: rotate(180deg);
}

.arrowDown {
    width: 40px;
    height: 40px;
    cursor: pointer;
}
