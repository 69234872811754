@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

.modal--holder {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 115px);
  z-index: 9999;
  background-color: whitesmoke;
  display: grid;
  justify-content: center;
  align-items: center;
  top: 0;
}
.modal {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 32px;
}
.modal__heading {
  font-size: 20px;
  font-weight: bold;
  color: #012a4a;
}
.modal__text {
  font-size: 14px;
  color: #1f5679;
  white-space: pre-wrap;
  line-height: 1;
}
.modal__buttons {
  display: flex;
  gap: 8px;
}
.modal__button {
  padding: 8px 24px;
  background-color: transparent;
  border: 1px solid #012a4a;
  border-radius: 4px;
  font-weight: bold;
}
.modal__button--primary {
  background-color: #012a4a;
  color: white;
}
.modal__button--secondary {
  border: 1px solid #989ab5;
  color: #012a4a;
}
.modal__button:hover {
  cursor: pointer;
}
