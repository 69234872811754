.copy-task{
    border-style: solid;
    border-color: #000000;
    border-radius: 8px;
    padding: 10px;
    z-index: 1000 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.copy-task-select {
    height: 2.77em;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: .3125em;
    padding-right: 30px;
    padding-left: 10px;
    background-position: 95% 53%, center center;
    background-repeat: no-repeat, repeat-x;
    color: #333333;
    font-size: 1em;
    line-height: 1.3;
    font-family: sans-serif;
    font-weight: bold;
}