.reminder-select-ul-react {
    position: absolute;
    right: 20%;
    bottom: 90px;
    border: 1px solid black;
    border-radius: 10px;
}

.reminder-ul-selected {
    background-color: #292A3C !important;
    color: white !important;
}
