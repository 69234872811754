.p-1 {
  padding: 5px;
}
.salesHorseImage {
  width: 50px !important;
  height: 50px !important;
}
.text-2-rows {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-height: 1.5;
  max-height: 3em;
  white-space: normal;
  cursor: pointer;
}
