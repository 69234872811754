.trainingPopupFooter {
  display: flex;
  justify-content: center;
  align-items: self-end;
}
.ui-btn-inline {
  margin-right: 5px;
}
#trainingEditWindowReact {
  box-shadow: 2px 3px 5px #999;
  padding-bottom: 10px;
  z-index: 100 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}
#trainingEditP {
  display: none;
}
@media screen and (max-width: 700px) {
  .trainingPopupFooter {
    gap: 5px;
  }
  .ui-btn-inline {
    font-size: 14px;
    padding: 15px;
    padding-left: 5px;
    padding-right: 5px;
  }
  #trainingEditP {
    display: block;
  }
  #trainingEditWindowReact {
    display: none;
  }
}
#taskHeaderReact {
  height: 42px;
  background-color: #292a3c !important;
  color: #ffffff !important;
  cursor: grab;
}

#cancelEditTrainingReact {
  height: 36px;
  float: left;
  margin-top: 2px;
  margin-left: 5px;
  filter: invert(100%);
  cursor: pointer;
}

#tcOkButtonReact {
  height: 30px;
  float: right;
  margin-top: 5px;
  margin-right: 8px;
  filter: invert(100%);
  cursor: pointer;
}

.dateTrainingDivReact {
  position: absolute;
  left: 50%;
  top: 2px;
  transform: translate(-50%, 0px);
  z-index: 1;
}

.dateTrainingDivReact div {
  margin: 0px;
}

.dateTrainingDivReact {
  background-color: transparent;
  color: white;
  text-shadow: none;
  cursor: pointer;
}

.trainingEditWindowReactTable {
  width: 100%;
  table-layout: fixed;
  border-spacing: 20px 0;
  margin-top: 10px;
}

.react-datepicker-wrapper {
  display: inline !important;
}

.react-datepicker__input-container {
  border-radius: 10px;
}

.ui-input-text input {
  text-align: center !important;
}

.react-datepicker__header {
  background: #292a3c !important;
  color: white;
}

.react-datepicker__day--selected {
  background: #292a3c !important;
}

.react-datepicker__current-month {
  color: white !important;
}

.react-datepicker__day-name {
  color: white !important;
}

.openTimeSelectPopup,
.openExtraTrainingButton {
  background-color: #ffffff;
  border-color: #111111;
  color: #333333;
  text-shadow: 0 0px 0 #f3f3f3;
}

.ContentForMobile {
  padding-top: 0px !important;
}

.timePopup {
  border-style: solid;
  border-color: #000000;
  border-radius: 8px;
  background-color: white;
  min-width: 200px;
  padding: 10px;
  z-index: 1000 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timePopup--input-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.timePopup--ios-reset {
  border: 1px solid gray;
  padding: 2px;
  border-radius: 100%;
  margin-left: 5px;
  width: 15px !important;
  height: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

.iplusFilesImgBorder {
  width: 15mm;
  height: 14mm;
  border: 1px solid black;
  border-radius: 0.3125em;
  padding: 5px;
}

.field-note {
  padding-top: 10px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

#dewormingStageSelect {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 250px;
}
