#farrierTable {
    margin-top: 45px;
}

.FarrTable {
    width: 100%;
    border-collapse: collapse;
}

    .FarrTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .FarrTable th {
        border: 1px solid gray;
    }

    .FarrTable tr > th {
        background-color: #292A3C;
        color: #FFFFFF;
        text-shadow: 0 0 0;
    }
