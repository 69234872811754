.DewormingTable {
  margin-top: 20px;
}
.Deworming-time-picker {
  width: 100%;
  background-color: rgb(41, 42, 60);
  justify-content: center;
  display: flex;
}
.Deworming-time-picker input {
  text-align: center;
  border: none;
  outline: none;
}

#dewormingStageSelect,
#dewormingStageSelectAddPopup {
  margin-left: 26px;
  max-width: 85%;
}
#dewormingStageSelectAddPopup {
  margin-left: 0;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Deworming-time-picker > div input {
  width: 99%;
}
.dewormingEditSelect {
  display: flex;
  justify-content: space-around;
}
.text-center {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dewormingRemaininText {
  margin-top: 50px;
}
.SecondTable {
  margin-top: 5px;
}

.editDewormingIsDoneCheckbox {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-left: 3px;
  padding-bottom: 5px;
}
