.field-area-scrollable {
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}
.field-area-section--title-holder {
  display: flex;
  align-items: center;
}
.field-area-section--title-holder > h3 {
  margin-left: 10px;
}
.field-manager--scroll-area {
  max-height: 100vh;
  overflow-y: auto;
}
.field-manager--holder {
  margin-top: 50px;
}

.field-area-section {
  overflow-y: auto;
  padding: 10px;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0;
  border-bottom: 1px solid gray;
}

.field-area-section--title {
  font-size: 1.55rem;
}

.field-area-section--data-holder {
  display: inline-flex;
  gap: 4em;
  flex-wrap: wrap;
}

.field-area-section--table,
.field-area-section--table th,
.field-area-section--table td {
  border: 1px solid black;
  border-collapse: collapse;
  height: 50px;
}

.field-area-section--table th:first-child,
.field-area-section--table td span:first-of-type {
  text-align: left;
  padding-left: 40px;
}
.field-area-section--table td span {
  font-weight: bold;
}
.field-area-section--table--header-text {
  padding-left: 10px;
  padding-right: 10px;
}
.field-area-section--table--field-name {
  min-width: 300px;
}
.field-area-section--table--field-name--inner {
  display: flex;
  align-items: center;
  justify-content: start;
}
.field-area-section--table--field-name--inner > span {
  padding-left: 10px !important;
}
.field-area-section--table--field-horses {
  text-align: center;
  font-weight: 600;
}
.field-area-section--table--field-button {
  min-width: 60px;
}
.field-area-section--table--field-button div {
  width: auto;
  display: flex;
  justify-content: center;
  margin: 5px;
}
.field-area-section--table--field-button img {
  height: 40px;
}

.field-btn {
  max-width: 200px;
  border-radius: 10px;
  margin-left: 5px;
  text-align: left;
  height: 50px;
}

.field-area-btn {
  max-width: 250px;
  border-radius: 10px;
  margin-left: 10px;
}

/* MODAL */
.field-manager--modal-text-input {
  border: none;
  border-bottom: 1px solid gray;
  outline: none;

  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .field-area-section {
    width: 100%;
    margin: 0;
  }
}

.field-manager--modal--holder {
  display: flex;
  flex-direction: column;
}

.tcDelButton--field-area {
  width: 35px;
  height: 35px;
}

.field-area-section--enforce-fields {
  width: 50%;
  display: flex;
  margin-left: auto;
}
.checkbox {
  accent-color: rgba(37, 64, 83, 1);
  margin-left: auto;
  margin-right: 10px;
}
