.fieldAndOtherTasksViewHolderWrapper {
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}
.fieldAndOtherTasksViewScrollableArea {
  height: auto;
  display: flex;
}

.fieldAndOtherTasksViewScrollableArea > div {
  height: auto;
  overflow: auto;
  max-height: auto;
  height: auto;
  flex: 1;
}
.fieldAndOtherTasksViewScrollableArea > div:nth-child(2) {
  padding-top: 80px;
}

.fieldAndOtherTasksViewScrollableArea > div:nth-child(1) {
  max-height: unset !important;
}
.fieldAndOtherTasksViewScrollableArea > div:nth-child(1) > div {
  width: auto I !important;
}
@media screen and (max-width: 600px) {
  .fieldAndOtherTasksViewScrollableArea {
    flex-direction: column;
    padding-bottom: 50px;
  }
}
