.notify {
  border: none;
  outline: none;
  background: #262626;
  padding: 15px 50px;
  border-radius: 30px;
  font-size: 22px;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
  z-index: 999999;
}

.popup {
  position: fixed;
  bottom: -60px;
  right: 10px;
  background: #012a4a;
  padding: 15px 40px;
  font-size: 15px;
  font-family: sans-serif;
  color: #ffffff;
  border-radius: 15px;
  animation: popup 5s ease-in-out;
}

@keyframes popup {
  10%,
  90% {
    bottom: 10px;
  }
  100% {
    bottom: -60px;
  }
}
