.horseIcon {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    background-position: center center;
    background-size: cover;
    margin-right: 5px;
    vertical-align: top;
    margin-top: 10px;
}

.horseNameContainer {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
}

.horseName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
}

.horseNotes {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.6em;
    margin-bottom: -2px;
    min-height: 1em;
}

.horseBottomText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.6em;
    margin-top: -2px;
}
