.horse-gallery-holder {
  margin-bottom: env(safe-area-inset-top);
}
.hgHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid black;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.hgHeaderIcon {
  height: 40px;
  cursor: pointer;
}

.hgThumbImagesList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.hgImageItem {
  margin: 10px;
}

.hgImageName {
  height: 28px;
  position: relative;
  width: 200px;
}

.hgImageNameInput {
  width: 87%;
  border: none;
  outline: none;
}
.hgImageNameTitle {
  display: block;
  max-width: 180px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.hgImageNameSave {
  height: 17.5px;
  position: absolute;
  right: 2px;
  top: 3px;
}
.containerHgImageNameInput {
  border: 1px solid black;
}
.hgImageNameContainer {
}
.hgThumbImage {
  width: 200px;
  height: 150px;
  /*background-position: center center;*/
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.hgThumbImagePlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.hgThumbSelectIcon {
  position: absolute;
  height: 30px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  border-radius: 50%;
}

.hgFullScreenView {
  position: fixed;
  left: 0px;
  right: 0px;
  top: env(safe-area-inset-top);
  bottom: 0px;
  background-color: white;
  z-index: 1001;
}

.hgFullScreenItem {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.hgFullScreenYoutube {
  margin-top: 50px;
  width: 100%;
  height: calc(100% - 50px);
}

.hgFullScreenClose {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  right: 0px;
  top: env(safe-area-inset-top);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

.hgFullScreenShare {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  right: 60px;
  top: env(safe-area-inset-top);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

.hgFullScreenLeft {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  left: env(safe-area-inset-top);
  top: calc(50% - 25px);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

.hgFullScreenRight {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  right: 0px;
  top: calc(50% - 25px);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

.hgAddYoutubePopup {
  position: fixed;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  z-index: 100;
}

.hgAddYoutubePopupClose {
  position: absolute;
  width: 40px;
  height: 40px;
  right: -20px;
  top: -20px;
  cursor: pointer;
  z-index: 2147483647;
}

.hgInput {
  min-height: 2.2em;
  text-align: left;
  border: 1px solid #dddddd;
  border-radius: 9px;
}
