.oipContent {
    margin-top: 45px;
    padding: 10px;
    height: calc(100vh - 130px);
    overflow-y: auto;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

#ownerInfoContent input {
    text-align: center;
}

#ownerInfoContent input[disabled] {
    background-color: #C9D2E2;
}