.twoRidersDiv {
  max-height: calc(100vh - 180px);
  overflow-x: auto;
  overflow-y: auto;
}

.twoRidersDivMobile {
  max-height: calc(100vh - 240px);
  overflow-x: auto;
  overflow-y: auto;
}

.twoRidersContainer {
  display: flex;
  flex-wrap: wrap;
}

.riderTableR {
  border-collapse: collapse;
  border-left: 4px solid gray;
  background-color: white;
  width: 100%;
}

.riderTableR thead td {
  font-size: small;
  height: 30px !important;
}

.riderTableR > tbody > tr > td:first-child,
.riderTableR > thead > tr > td:first-child {
  max-width: 180px;
  width: 180px;
}

.riderTableR > tbody > tr > td,
.riderTableR > thead > tr > td {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  text-align: center;
  height: 40px;
  width: 350px;
  padding: 0px;
}
