.farrierTable {
    width: 100%;
}

    .farrierTable td {
        text-align: center;
        vertical-align: middle;
    }

.topRow {
    display: flex;
}

    .topRow button {
        width: initial;
        margin-right: 5px;
        margin-left: 5px;
    }

.actionIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.reportFarrButton {
    margin-top: 6px;
    height: 46px;
}
