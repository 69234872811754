.sharedWithMeRow {
    text-align: left !important;
    height: 20px !important;
}

.tableWeekView {
    width: calc(100vw - 20px);
    border-collapse: collapse;
    table-layout: fixed;
}

.tableWeekViewMobile {
    width: calc(100vw);
}

    .tableWeekView thead td {
        font-size: small;
        height: 50px !important;
    }

    .tableWeekView > tbody > tr > td,
    .tableWeekView > thead > tr > td {
        border-right: 4px solid gray;
        border-bottom: 1px solid gray;
        text-align: center;
        height: 40px;
        padding: 0px;
    }

.scrollable {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}
.scrollableMobile {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}

.todayHeader {
    color: red !important;
}

.equipmentInput {
    height: 100%;
    margin: 0px;
    width: 100%;

    background-color: #FFFFFF;
    border-color: #dddddd;
    color: #333333;
    text-shadow: 0 1px 0 #f3f3f3;
}

.permanentHeader {
    width: 120px;
}