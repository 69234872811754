#vaccinationNextPopup {
    box-shadow: 2px 3px 5px #999;
    padding-bottom: 10px;
    z-index: 100 !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.AllVaccinationTable {
    width: 100%;
    border-collapse: collapse;
}

    .AllVaccinationTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .AllVaccinationTable th {
        border: 1px solid gray;
    }

    .AllVaccinationTable tr > th {
        text-shadow: 0 0 0;
    }
