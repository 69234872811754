.ppContent {
    margin-top: 45px;
    padding: 10px;
}

.ppTitle {
    color: white;
    font-size: 1.2em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ppTable {
    width: 100%;
}

.ppLabel {
    width: 5%;
}

.ppButton {
    width: initial !important;
    margin-right: 10px;
}

.ppDeleteIcon {
    width: 15px;
    height: 15px;
    margin-left: 15px;
}

.ppTextarea {
    width: 100%;
    height: 250px !important;
}

.ppAddIcon {
    height: 35px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 15px;
}

#inputDataTable {
    width: 40%;
    padding-left: 5px;
    padding-right: 5px;
}
