.allRidersDiv {
  max-height: calc(100vh - 180px);
  overflow-x: auto;
  overflow-y: auto;
}

.allRidersDivMobile {
  max-height: calc(100vh - 240px);
  overflow-x: auto;
  overflow-y: auto;
}

.allRidersTable {
  border-collapse: collapse;
  width: max-content;
}

.allRidersTable > tbody > tr > td {
  vertical-align: top;
  padding: 0px;
}

.allRidersContainer {
  width: max-content;
  display: flex;
}

.riderTableR {
  border-collapse: collapse;
  /*table-layout: fixed;*/
  border-left: 4px solid gray;
  background-color: white;
  width: 100%;
}

.riderTableR thead td {
  font-size: small;
  height: 30px !important;
}

.riderTableR > tbody > tr > td:first-child,
.riderTableR > thead > tr > td:first-child {
  max-width: 180px;
  width: 180px;
  min-width: 90px;
}

.riderTableR > tbody > tr > td,
.riderTableR > thead > tr > td {
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  text-align: center;
  height: 40px;
  /* min-width: 150px;*/
  width: 350px;
  padding: 0px;
}

.equipmentInput {
  height: 100%;
  width: 100%;
  margin: 0px;
  margin-top: 10px;
  background-color: #ffffff;
  border-color: #dddddd;
  color: #333333;
  text-shadow: 0 1px 0 #f3f3f3;
}

.resizeDivider {
  background-color: rgba(255, 255, 255, 0.6);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: -12px;
  z-index: 2;
  position: absolute;
}
