#teethTable {
    margin-top: 65px;
}

.TeethTable {
    width: 100%;
    border-collapse: collapse;
}

    .TeethTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .TeethTable th {
        border: 1px solid gray;
    }

    .TeethTable tr > th {
        text-shadow: 0 0 0;
    }
