.raiderAndOtherTasksWrapper {
  overflow-x: scroll;
  max-width: 100vw;
  max-height: calc(100vh - 115px);
}

.raiderAndOtherTasksContainer {
  display: flex;
}

.raiderAndOtherTasksRaider div {
  max-width: unset;
  overflow-x: hidden;
}
.raiderAndOtherTasksOtherTasks {
  margin-left: 25px;
  margin-right: 25px;
  min-width: min(100vw, 550px);
}
