.tvContainerChecked {
    background-color: #292A3C;
    padding: 2px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    margin-top: .5em;
    margin-bottom: .5em;
}

    .tvContainerChecked .tvLabel {
        color: white;
    }

.tvContainerUnhecked {
    border: 1px solid #292A3C;
    background-color: white;
    padding: 2px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    margin-top: .5em;
    margin-bottom: .5em;
}

    .tvContainerUnhecked .tvLabel {
        color: #292A3C;
    }

.tvButton {
    border: 1px solid #292A3C;
    background-color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
}

.tvLabel {
    font-weight: bold;
    width: 60px;
}
