@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
.sharedWithMeRow {
    text-align: left !important;
    height: 20px !important;
}

.tableWeekView {
    width: calc(100vw - 20px);
    border-collapse: collapse;
    table-layout: fixed;
}

.tableWeekViewMobile {
    width: calc(100vw);
}

    .tableWeekView thead td {
        font-size: small;
        height: 50px !important;
    }

    .tableWeekView > tbody > tr > td,
    .tableWeekView > thead > tr > td {
        border-right: 4px solid gray;
        border-bottom: 1px solid gray;
        text-align: center;
        height: 40px;
        padding: 0px;
    }

.scrollable {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}
.scrollableMobile {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}

.todayHeader {
    color: red !important;
}

.equipmentInput {
    height: 100%;
    margin: 0px;
    width: 100%;

    background-color: #FFFFFF;
    border-color: #dddddd;
    color: #333333;
    text-shadow: 0 1px 0 #f3f3f3;
}

.permanentHeader {
    width: 120px;
}
.horseIcon {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    background-position: center center;
    background-size: cover;
    margin-right: 5px;
    vertical-align: top;
    margin-top: 10px;
}

.horseNameContainer {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
}

.horseName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
}

.horseNotes {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.6em;
    margin-bottom: -2px;
    min-height: 1em;
}

.horseBottomText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.6em;
    margin-top: -2px;
}

.trainingPopupFooter {
  display: flex;
  justify-content: center;
  align-items: self-end;
}
.ui-btn-inline {
  margin-right: 5px;
}
#trainingEditWindowReact {
  box-shadow: 2px 3px 5px #999;
  padding-bottom: 10px;
  z-index: 100 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}
#trainingEditP {
  display: none;
}
@media screen and (max-width: 700px) {
  .trainingPopupFooter {
    grid-gap: 5px;
    gap: 5px;
  }
  .ui-btn-inline {
    font-size: 14px;
    padding: 15px;
    padding-left: 5px;
    padding-right: 5px;
  }
  #trainingEditP {
    display: block;
  }
  #trainingEditWindowReact {
    display: none;
  }
}
#taskHeaderReact {
  height: 42px;
  background-color: #292a3c !important;
  color: #ffffff !important;
  cursor: grab;
}

#cancelEditTrainingReact {
  height: 36px;
  float: left;
  margin-top: 2px;
  margin-left: 5px;
  filter: invert(100%);
  cursor: pointer;
}

#tcOkButtonReact {
  height: 30px;
  float: right;
  margin-top: 5px;
  margin-right: 8px;
  filter: invert(100%);
  cursor: pointer;
}

.dateTrainingDivReact {
  position: absolute;
  left: 50%;
  top: 2px;
  transform: translate(-50%, 0px);
  z-index: 1;
}

.dateTrainingDivReact div {
  margin: 0px;
}

.dateTrainingDivReact {
  background-color: transparent;
  color: white;
  text-shadow: none;
  cursor: pointer;
}

.trainingEditWindowReactTable {
  width: 100%;
  table-layout: fixed;
  border-spacing: 20px 0;
  margin-top: 10px;
}

.react-datepicker-wrapper {
  display: inline !important;
}

.react-datepicker__input-container {
  border-radius: 10px;
}

.ui-input-text input {
  text-align: center !important;
}

.react-datepicker__header {
  background: #292a3c !important;
  color: white;
}

.react-datepicker__day--selected {
  background: #292a3c !important;
}

.react-datepicker__current-month {
  color: white !important;
}

.react-datepicker__day-name {
  color: white !important;
}

.openTimeSelectPopup,
.openExtraTrainingButton {
  background-color: #ffffff;
  border-color: #111111;
  color: #333333;
  text-shadow: 0 0px 0 #f3f3f3;
}

.ContentForMobile {
  padding-top: 0px !important;
}

.timePopup {
  border-style: solid;
  border-color: #000000;
  border-radius: 8px;
  background-color: white;
  min-width: 200px;
  padding: 10px;
  z-index: 1000 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timePopup--input-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.timePopup--ios-reset {
  border: 1px solid gray;
  padding: 2px;
  border-radius: 100%;
  margin-left: 5px;
  width: 15px !important;
  height: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

.iplusFilesImgBorder {
  width: 15mm;
  height: 14mm;
  border: 1px solid black;
  border-radius: 0.3125em;
  padding: 5px;
}

.field-note {
  padding-top: 10px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

#dewormingStageSelect {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 250px;
}

.copy-task{
    border-style: solid;
    border-color: #000000;
    border-radius: 8px;
    padding: 10px;
    z-index: 1000 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.copy-task-select {
    height: 2.77em;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: .3125em;
    padding-right: 30px;
    padding-left: 10px;
    background-position: 95% 53%, center center;
    background-repeat: no-repeat, repeat-x;
    color: #333333;
    font-size: 1em;
    line-height: 1.3;
    font-family: sans-serif;
    font-weight: bold;
}
.reminder-select-ul-react {
    position: absolute;
    right: 20%;
    bottom: 90px;
    border: 1px solid black;
    border-radius: 10px;
}

.reminder-ul-selected {
    background-color: #292A3C !important;
    color: white !important;
}

.horse-gallery-holder {
  margin-bottom: env(safe-area-inset-top);
}
.hgHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid black;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.hgHeaderIcon {
  height: 40px;
  cursor: pointer;
}

.hgThumbImagesList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.hgImageItem {
  margin: 10px;
}

.hgImageName {
  height: 28px;
  position: relative;
  width: 200px;
}

.hgImageNameInput {
  width: 87%;
  border: none;
  outline: none;
}
.hgImageNameTitle {
  display: block;
  max-width: 180px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.hgImageNameSave {
  height: 17.5px;
  position: absolute;
  right: 2px;
  top: 3px;
}
.containerHgImageNameInput {
  border: 1px solid black;
}
.hgImageNameContainer {
}
.hgThumbImage {
  width: 200px;
  height: 150px;
  /*background-position: center center;*/
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.hgThumbImagePlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.hgThumbSelectIcon {
  position: absolute;
  height: 30px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  border-radius: 50%;
}

.hgFullScreenView {
  position: fixed;
  left: 0px;
  right: 0px;
  top: env(safe-area-inset-top);
  bottom: 0px;
  background-color: white;
  z-index: 1001;
}

.hgFullScreenItem {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.hgFullScreenYoutube {
  margin-top: 50px;
  width: 100%;
  height: calc(100% - 50px);
}

.hgFullScreenClose {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  right: 0px;
  top: env(safe-area-inset-top);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

.hgFullScreenShare {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  right: 60px;
  top: env(safe-area-inset-top);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

.hgFullScreenLeft {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  left: env(safe-area-inset-top);
  top: calc(50% - 25px);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

.hgFullScreenRight {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  right: 0px;
  top: calc(50% - 25px);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

.hgAddYoutubePopup {
  position: fixed;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  z-index: 100;
}

.hgAddYoutubePopupClose {
  position: absolute;
  width: 40px;
  height: 40px;
  right: -20px;
  top: -20px;
  cursor: pointer;
  z-index: 2147483647;
}

.hgInput {
  min-height: 2.2em;
  text-align: left;
  border: 1px solid #dddddd;
  border-radius: 9px;
}

.pdfDocument {
    padding-top: 40px;
    overflow: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.pdfPages {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}


    .pdfPages .pdfArrowIcon {
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

.card__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2147483647; /*maximum z-index*/
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  justify-items: center;
}
.card {
  border-radius: 0.75rem;
  background-color: #fff;
  overflow: hidden;
  flex: 1 1;
  margin: auto;
  margin-left: 15px;
  margin-right: 15px;
}
.info__padding {
  padding: 20px 40px 10px 40px;
}

.button__group button {
  color: #007aff;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 18px 10px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid rgb(220, 224, 226);
}
.button__group:after {
  content: "";
  clear: both;
  display: table;
}
.button__group button:not(:last-child) {
  border-left: none;
}
.button__group button:hover {
  background-color: rgb(250, 250, 250);
}
.button__group button:active {
  background-color: rgb(245, 245, 245);
}

.traningDayTable {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.traningDayTable td {
  border-right: 1px solid gray;
}

.trainingCellContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.trainingCell {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.trainingCellName {
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
}

.trainingCellNameSmall {
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
}

.trainingCellNotDone {
  color: red;
}
.trainingCellNotDoneTimeSet {
  color: green;
}

.trainingCellDone {
  text-decoration: line-through;
  text-shadow: none;
}

.trainingCellTime {
  font-size: 0.6em;
  text-align: center;
  margin-bottom: -4px;
}

.trainingCellNotes {
  font-size: 0.6em;
  text-align: center;
  margin-top: -4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.sharedWithMeRow {
    text-align: left !important;
    height: 20px !important;
}

.tableWeekView {
    width: calc(100vw - 20px);
    border-collapse: collapse;
    table-layout: fixed;
}

.tableWeekViewMobile {
    width: calc(100vw);
}

.tableWeekView thead td {
    font-size: small;
}

.tableWeekView > tbody > tr > td,
.tableWeekView > thead > tr > td {
    border-right: 4px solid gray;
    border-bottom: 1px solid gray;
    text-align: center;
    height: 50px;
    padding: 0px;
}

.feedHeader {
    width: 98%;
    height: 98%;
    margin-top: 2%;
    text-align: center;
}

.scrollableFeed {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}

.scrollableMobileFeed {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}

.feedinput {
    width: 98%;
    height: 98%;
    margin-top: 2%;
}

.feedDeleteHeaderDiv {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    border: 1px solid gray;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px;
    border-radius: 10px;
    background-color: lightgray;
}

.feedDeleteTitle {
    text-align: center;
    padding: 7px;
    border-bottom: 1px solid black;
}

.feedDeleteBottom {
    text-align: center;
    padding: 7px;
    font-weight: bold;
    cursor: pointer;
}

.feedDeleteItem {
    padding: 10px;
    border-bottom: 1px solid black;
    background-color: white;
    font-size: 1em;
    cursor: pointer;
}
.allRidersDiv {
  max-height: calc(100vh - 180px);
  overflow-x: auto;
  overflow-y: auto;
}

.allRidersDivMobile {
  max-height: calc(100vh - 240px);
  overflow-x: auto;
  overflow-y: auto;
}

.allRidersTable {
  border-collapse: collapse;
  width: max-content;
}

.allRidersTable > tbody > tr > td {
  vertical-align: top;
  padding: 0px;
}

.allRidersContainer {
  width: max-content;
  display: flex;
}

.riderTableR {
  border-collapse: collapse;
  /*table-layout: fixed;*/
  border-left: 4px solid gray;
  background-color: white;
  width: 100%;
}

.riderTableR thead td {
  font-size: small;
  height: 30px !important;
}

.riderTableR > tbody > tr > td:first-child,
.riderTableR > thead > tr > td:first-child {
  max-width: 180px;
  width: 180px;
  min-width: 90px;
}

.riderTableR > tbody > tr > td,
.riderTableR > thead > tr > td {
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  text-align: center;
  height: 40px;
  /* min-width: 150px;*/
  width: 350px;
  padding: 0px;
}

.equipmentInput {
  height: 100%;
  width: 100%;
  margin: 0px;
  margin-top: 10px;
  background-color: #ffffff;
  border-color: #dddddd;
  color: #333333;
  text-shadow: 0 1px 0 #f3f3f3;
}

.resizeDivider {
  background-color: rgba(255, 255, 255, 0.6);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: -12px;
  z-index: 2;
  position: absolute;
}

#newGeneralTaskTypeWindowReact {
  box-shadow: 2px 3px 5px #999;
  z-index: 100 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

#newGeneralTaskTypeReact {
  height: 42px;
  background-color: #292a3c !important;
  color: #ffffff !important;
}

#generalTaskPopupCopyDays {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  max-width: 600px;
  margin: 5px;
}

#generalTaskPopupCopyDays td {
  max-width: 120px;
  min-width: 120px;
  flex: 1 1;
}
#generalTaskPopupCopyDays td div {
  grid-gap: 5px;
  gap: 5px;
}

#cancelNewGeneralTaskTypeReact {
  height: 36px;
  float: left;
  margin-top: 2px;
  margin-left: 5px;
  filter: invert(100%);
  cursor: pointer;
}

#OkButtonReact {
  height: 30px;
  float: right;
  margin-top: 5px;
  margin-right: 8px;
  filter: invert(100%);
  cursor: pointer;
}

#inputNewGeneralTaskTypeReact {
  width: -webkit-fill-available;
  text-align: left !important;
}

@media screen and (max-width: 814px) {
  #generalTaskPopupCopyDays {
    justify-content: center;
  }
}

.twoRidersDiv {
  max-height: calc(100vh - 180px);
  overflow-x: auto;
  overflow-y: auto;
}

.twoRidersDivMobile {
  max-height: calc(100vh - 240px);
  overflow-x: auto;
  overflow-y: auto;
}

.twoRidersContainer {
  display: flex;
  flex-wrap: wrap;
}

.riderTableR {
  border-collapse: collapse;
  border-left: 4px solid gray;
  background-color: white;
  width: 100%;
}

.riderTableR thead td {
  font-size: small;
  height: 30px !important;
}

.riderTableR > tbody > tr > td:first-child,
.riderTableR > thead > tr > td:first-child {
  max-width: 180px;
  width: 180px;
}

.riderTableR > tbody > tr > td,
.riderTableR > thead > tr > td {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  text-align: center;
  height: 40px;
  width: 350px;
  padding: 0px;
}


@media screen and (max-width: 500px) {
  .otherTasksViewTable {
    min-width: 100vw;
  }
  .otherTasksViewTable tr td {
    max-width: 100vw !important;
  }

  .otherTasksViewHolderWrapper {
    flex-direction: column !important;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;
  }
  .otherTasksViewHolderScrollArea {
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(100vh - 175px);
  }
}
@media screen and (min-width: 500px) {
  .otherTasksViewHolderWrapper {
    all: unset !important;
  }
  .otherTasksViewHolderScrollArea {
    overflow-y: auto;
    max-height: calc(100vh - 175px);
  }
  .otherTasksViewHolder {
    display: flex;
    justify-content: flex-start;
  }
  .otherTasksViewTable tr td {
    max-width: 340px !important;
  }
}
.otherTasksViewTable--task-name {
  overflow-wrap: break-word;
}

.otherTasksViewTable,
.otherTasksViewTable tr,
.otherTasksViewTable tr td {
  border: none !important;
}

.otherTasksViewTable,
.otherTasksViewTable tr {
  border: 1px solid gray !important;
}
.otherTasksViewTable--last-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.otherTasksViewTable--last-row span {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
}
.otherTasksViewTable--last-row--reorder {
  margin-top: 3px;
}
.otherTasksViewTable--reorder-column {
  width: 30px !important;
}


.fieldAndOtherTasksViewHolderWrapper {
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}
.fieldAndOtherTasksViewScrollableArea {
  height: auto;
  display: flex;
}

.fieldAndOtherTasksViewScrollableArea > div {
  height: auto;
  overflow: auto;
  max-height: auto;
  height: auto;
  flex: 1 1;
}
.fieldAndOtherTasksViewScrollableArea > div:nth-child(2) {
  padding-top: 80px;
}

.fieldAndOtherTasksViewScrollableArea > div:nth-child(1) {
  max-height: unset !important;
}
.fieldAndOtherTasksViewScrollableArea > div:nth-child(1) > div {
  width: auto I !important;
}
@media screen and (max-width: 600px) {
  .fieldAndOtherTasksViewScrollableArea {
    flex-direction: column;
    padding-bottom: 50px;
  }
}



.p-1 {
  padding: 5px;
}
.salesHorseImage {
  width: 50px !important;
  height: 50px !important;
}
.text-2-rows {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-height: 1.5;
  max-height: 3em;
  white-space: normal;
  cursor: pointer;
}

.modal--holder {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 115px);
  z-index: 9999;
  background-color: whitesmoke;
  display: grid;
  justify-content: center;
  align-items: center;
  top: 0;
}
.modal {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  grid-gap: 16px;
  gap: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 32px;
}
.modal__heading {
  font-size: 20px;
  font-weight: bold;
  color: #012a4a;
}
.modal__text {
  font-size: 14px;
  color: #1f5679;
  white-space: pre-wrap;
  line-height: 1;
}
.modal__buttons {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.modal__button {
  padding: 8px 24px;
  background-color: transparent;
  border: 1px solid #012a4a;
  border-radius: 4px;
  font-weight: bold;
}
.modal__button--primary {
  background-color: #012a4a;
  color: white;
}
.modal__button--secondary {
  border: 1px solid #989ab5;
  color: #012a4a;
}
.modal__button:hover {
  cursor: pointer;
}

.raiderAndOtherTasksWrapper {
  overflow-x: scroll;
  max-width: 100vw;
  max-height: calc(100vh - 115px);
}

.raiderAndOtherTasksContainer {
  display: flex;
}

.raiderAndOtherTasksRaider div {
  max-width: unset;
  overflow-x: hidden;
}
.raiderAndOtherTasksOtherTasks {
  margin-left: 25px;
  margin-right: 25px;
  min-width: min(100vw, 550px);
}

.farrierTable {
    width: 100%;
}

    .farrierTable td {
        text-align: center;
        vertical-align: middle;
    }

.topRow {
    display: flex;
}

    .topRow button {
        width: initial;
        margin-right: 5px;
        margin-left: 5px;
    }

.actionIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.reportFarrButton {
    margin-top: 6px;
    height: 46px;
}

.tvContainerChecked {
    background-color: #292A3C;
    padding: 2px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    margin-top: .5em;
    margin-bottom: .5em;
}

    .tvContainerChecked .tvLabel {
        color: white;
    }

.tvContainerUnhecked {
    border: 1px solid #292A3C;
    background-color: white;
    padding: 2px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    margin-top: .5em;
    margin-bottom: .5em;
}

    .tvContainerUnhecked .tvLabel {
        color: #292A3C;
    }

.tvButton {
    border: 1px solid #292A3C;
    background-color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
}

.tvLabel {
    font-weight: bold;
    width: 60px;
}

.VaccinationTable {
  width: auto;
  border-collapse: collapse;
}

.VaccinationTable td {
  border: 1px solid gray;
  text-align: center;
  vertical-align: middle;
}
#vaccinationViewDiv {
  overflow-x: auto;
}
.VaccinationTable th {
  border: 1px solid gray;
}

.VaccinationTable tr > th {
  background-color: #292a3c;
  color: #ffffff;
  text-shadow: 0 0 0;
}

.remainingHorsesVaccinationTable td {
  text-align: center;
  vertical-align: middle;
}

.actionIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.reportVaccButton {
  margin-top: 6px;
  height: 46px;
}

#vaccinationEditWindowReact,
.editWindow {
  box-shadow: 2px 3px 5px #999;
  padding-bottom: 10px;
  z-index: 100 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vaccinationEditWindowReactTable {
  width: 100%;
  table-layout: fixed;
  border-spacing: 20px 0;
  margin-top: 10px;
}

.dateVaccinationDivReact {
  z-index: 1;
}

.dateVaccinationDivReact div {
  margin: 0px;
}

.dateVaccinationDivReact {
  background-color: transparent;
  color: white;
  text-shadow: none;
  cursor: pointer;
}

#vacAddTypeReact {
  background-color: red;
  width: 100%;
}

.task_done_td {
  width: 200px;
}

.XrayTable {
    margin-top: 80px;
    width: 100%;
    border-collapse: collapse;
}

    .XrayTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .XrayTable th {
        border: 1px solid gray;
    }

    .XrayTable tr > th {
        background-color: #292A3C;
        color: #FFFFFF;
        text-shadow: 0 0 0;
    }

.pdfDocument {
    padding-top: 40px;
    overflow: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.pdfPages {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}


    .pdfPages .pdfArrowIcon {
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

.DewormingTable {
  margin-top: 20px;
}
.Deworming-time-picker {
  width: 100%;
  background-color: rgb(41, 42, 60);
  justify-content: center;
  display: flex;
}
.Deworming-time-picker input {
  text-align: center;
  border: none;
  outline: none;
}

#dewormingStageSelect,
#dewormingStageSelectAddPopup {
  margin-left: 26px;
  max-width: 85%;
}
#dewormingStageSelectAddPopup {
  margin-left: 0;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Deworming-time-picker > div input {
  width: 99%;
}
.dewormingEditSelect {
  display: flex;
  justify-content: space-around;
}
.text-center {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dewormingRemaininText {
  margin-top: 50px;
}
.SecondTable {
  margin-top: 5px;
}

.editDewormingIsDoneCheckbox {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
  margin-left: 3px;
  padding-bottom: 5px;
}

.hvpAppLogo {
  height: 60px;
  margin-left: 30px;
  margin-top: 2px;
  filter: invert(1);
}

.hvpRight {
  float: right;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.hvpIconRignt {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  margin-left: 5px;
}

.hvmLogo {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-color: white;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
  border: 3px solid black;
  z-index: 2;
}

.hvmEditIcon {
  margin-left: 20px;
  margin-right: -50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.hvpButtonsDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.hvpButton {
  position: relative;
  color: white;
  background-color: #292a3c;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 5px;
  text-align: center;
  cursor: pointer;
}

.hvpButton > span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hvpContent {
  margin-top: 60px;
  margin-left: 10px;
  margin-right: 10px;
}

.hvpImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  margin: auto;
  cursor: pointer;
}

.hvpFloatHalf {
  width: calc(50% - 6px);
  padding-left: 3px;
  padding-right: 3px;
}

.hvpFloatQuard {
  min-width: calc(25% - 6px);
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 0.5em;
}

.hvpEditButton {
  width: calc(50% - 6px) !important;
  margin: 3px;
  margin-bottom: 8px;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hvpEditButton img {
  height: 20px;
  margin-right: 5px;
}

.hvmpaddingbottom {
  padding-bottom: 80px;
}

.creatableSelect .css-b8ldur-Input {
  margin: 0 !important;
  padding: 0 !important;
}

.creatableSelect .ui-input-text {
  margin-left: 2px !important;
  margin-right: 2px !important;
  margin: 0;
  border-width: 0;
}

.oipContent {
    margin-top: 45px;
    padding: 10px;
    height: calc(100vh - 130px);
    overflow-y: auto;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

#ownerInfoContent input {
    text-align: center;
}

#ownerInfoContent input[disabled] {
    background-color: #C9D2E2;
}
.npContent {
    margin-top: 45px;
    padding: 10px;
}

.npTitle {
    color: white;
    font-size: 1.2em;
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.npButton {
    width: initial !important;
    margin-right: 10px;
}

.npDeleteIcon {
    width: 15px;
    height: 15px;
    margin-left: 15px;
}

.npTextarea {
    width: 100%;
    height: 250px !important;
}

.npAddIcon {
    height: 35px;
    cursor: pointer;
    margin-left: 5px;
}
.ppContent {
    margin-top: 45px;
    padding: 10px;
}

.ppTitle {
    color: white;
    font-size: 1.2em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ppTable {
    width: 100%;
}

.ppLabel {
    width: 5%;
}

.ppButton {
    width: initial !important;
    margin-right: 10px;
}

.ppDeleteIcon {
    width: 15px;
    height: 15px;
    margin-left: 15px;
}

.ppTextarea {
    width: 100%;
    height: 250px !important;
}

.ppAddIcon {
    height: 35px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 15px;
}

#inputDataTable {
    width: 40%;
    padding-left: 5px;
    padding-right: 5px;
}

#vaccinationTable {
    margin-top: 65px;
}

.VaccTable {
    width: 100%;
    border-collapse: collapse;
}

    .VaccTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .VaccTable th {
        border: 1px solid gray;
    }

    .VaccTable tr > th {
        text-shadow: 0 0 0;
    }

#vaccinationNextPopup {
    box-shadow: 2px 3px 5px #999;
    padding-bottom: 10px;
    z-index: 100 !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.AllVaccinationTable {
    width: 100%;
    border-collapse: collapse;
}

    .AllVaccinationTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .AllVaccinationTable th {
        border: 1px solid gray;
    }

    .AllVaccinationTable tr > th {
        text-shadow: 0 0 0;
    }

#farrierTable {
    margin-top: 45px;
}

.FarrTable {
    width: 100%;
    border-collapse: collapse;
}

    .FarrTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .FarrTable th {
        border: 1px solid gray;
    }

    .FarrTable tr > th {
        background-color: #292A3C;
        color: #FFFFFF;
        text-shadow: 0 0 0;
    }

#farrierPopup {
    box-shadow: 2px 3px 5px #999;
    padding-bottom: 10px;
    z-index: 100 !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

#teethTable {
    margin-top: 65px;
}

.TeethTable {
    width: 100%;
    border-collapse: collapse;
}

    .TeethTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .TeethTable th {
        border: 1px solid gray;
    }

    .TeethTable tr > th {
        text-shadow: 0 0 0;
    }

#nextTeethCheckPopup {
    box-shadow: 2px 3px 5px #999;
    padding-bottom: 10px;
    z-index: 100 !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

#teethAllPopup {
    box-shadow: 2px 3px 5px #999;
    padding-bottom: 10px;
    z-index: 100 !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.TeethTable {
    width: 100%;
    border-collapse: collapse;
}

    .TeethTable td {
        border: 1px solid gray;
        text-align: center;
        vertical-align: middle;
    }

    .TeethTable th {
        border: 1px solid gray;
    }

    .TeethTable tr > th {
        text-shadow: 0 0 0;
    }

.pdfDocument {
    padding-top: 40px;
    overflow: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.pdfPages {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}


    .pdfPages .pdfArrowIcon {
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

.sideMenuSelect {
    color: black;
    position: relative;
    max-width: 250px;
    margin: 5px;
}

#reportTableReact {
    overflow-x: auto;
}

.reportTable {
    width: max-content;
    border-collapse: collapse;
}

    .reportTable th {
        border: 1px solid gray;
    }

    .reportTable tr {
        border: 1px solid gray;
    }

    .reportTable td {
        border: 1px solid gray;
    }

.reportButton {
    height: 46px;
}

.arrowUp {
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-transform: rotate(180deg);
}

.arrowDown {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.ppContent {
    margin-top: 45px;
    padding: 10px;
}

.roleTable {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

    .roleTable tr {
        border: 1px solid gray;
    }

    .roleTable td {
        border: 1px solid gray;
    }

.collapsible {
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border-radius: 10px;
  border-color: black;
  text-align: left;
  outline: none;
  background: bottom;
}

.collapsible-content {
  padding: 0 18px;
  display: block;
  overflow: hidden;
}

.changeLicensePopup {
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  border-color: #111111;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.changeLicenseClose {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 40px;
  height: 40px;
}

.crImage {
  width: 50%;
  margin-left: 25%;
}

.reset-password--input-holder {
  margin-top: 5px;
}

.reset-password--input-holder input {
  -webkit-text-security: disc;
  text-security: disc;
}

.sharedHorses--column-holder {
  display: flex;
  align-items: center;
}

.sharedHorses--column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  gap: 5px;

  align-items: center;
  justify-items: center;
}

.sharedHorses--column-select {
  flex: auto;
  margin-bottom: 5px;
  max-width: 150px;
}

.ctContent {
    margin-top: 45px;
    padding: 10px;
}


.ctTable {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

    .ctTable th, .ctTable td {
        border: 1px solid gray;
        text-align: center;
    }

    .ctTable tr:last-child > td {
        text-align: center;
        border: initial;
    }

.ppContent {
    margin-top: 65px;
    padding: 10px;
}

.horseTypeTable {
    border-collapse: collapse;
    width: 80%;
    text-align: center;
}

    .horseTypeTable td, th {
        border: 1px solid gray;
    }

    .horseTypeTable tr:nth-last-child(-n+2) > td {
        text-align: center;
        border: initial;
    }

.ppContent {
    margin-top: 45px;
    padding: 10px;
}

.dewormingTypesTable {
    border-collapse: collapse;
    width: 80%;
    text-align: center;
}

    .dewormingTypesTable tr {
        border: 1px solid gray;
    }

    .dewormingTypesTable td {
        border: 1px solid gray;
    }

.sales-data--container {
  width: 100vw;
  display: block;
  max-height: calc(100vh - 30px);
  overflow-y: scroll;
  margin-top: 10px;
}
.sales-data--holder {
  margin-top: 50px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
}
.sales-data--profile--holder {
  display: flex;
  padding-bottom: 10px;
  width: calc(100vw - 60px);
  border-bottom: 1px solid gray;
}
.sales-data--title-holder {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.sales-data--filler {
  flex: auto;
}
.sales-data--profile {
  display: flex;
  justify-content: left;
}
.sales-data--action-buttons {
  margin-top: -5px;
  margin-left: auto;
}
.sales-data--gallery {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 10px;
}
.sales-data--toggle-holder {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 18px;
}

.sales-data--title-gender {
  font-size: 1.2em;
  text-align: center;
}

.sales-data--title-name {
  font-size: 1.8em;
  display: inline-block;
}
.sales-data--title-born-year {
  font-size: 1.2em;
  text-align: center;
}
.sales-data--profile-image {
  width: 60px;
  height: 60px;
  margin: 0;
  border: 1px solid black;
}
.empty--holder {
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
}
.empty--info {
  margin: auto;
}
.empty--illustration {
  display: flex;
  justify-content: center;
}
.empty--illustration svg {
  padding: 10px;
  border-radius: 100%;
  border: 1px solid rgb(130, 129, 151);

  background: linear-gradient(180deg, #e4e4e7 0%, rgba(208, 232, 240, 0) 100%);
}
.empty--title {
  margin-top: 10px;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .sales-data--profile--holder {
    flex-direction: column;
    padding-bottom: 45px;
  }
  .sales-data--holder {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
  }
  .sales-data--profile-image {
    width: 150px;
    height: 150px;
    margin: auto;
  }
  .sales-data--title-holder {
    flex-direction: column;
    padding-top: 10px;
    margin-bottom: 25px;
  }
  .sales-data--profile {
    justify-content: center;
    flex-direction: column;
  }
  .sales-data--gallery {
    margin-top: 10px;
  }
}

.basic-button {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.basic-button > svg {
  width: 25px;
  height: 25px;
}

.notify {
  border: none;
  outline: none;
  background: #262626;
  padding: 15px 50px;
  border-radius: 30px;
  font-size: 22px;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
  z-index: 999999;
}

.popup {
  position: fixed;
  bottom: -60px;
  right: 10px;
  background: #012a4a;
  padding: 15px 40px;
  font-size: 15px;
  font-family: sans-serif;
  color: #ffffff;
  border-radius: 15px;
  animation: popup 5s ease-in-out;
}

@keyframes popup {
  10%,
  90% {
    bottom: 10px;
  }
  100% {
    bottom: -60px;
  }
}

.field-area-scrollable {
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}
.field-area-section--title-holder {
  display: flex;
  align-items: center;
}
.field-area-section--title-holder > h3 {
  margin-left: 10px;
}
.field-manager--scroll-area {
  max-height: 100vh;
  overflow-y: auto;
}
.field-manager--holder {
  margin-top: 50px;
}

.field-area-section {
  overflow-y: auto;
  padding: 10px;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0;
  border-bottom: 1px solid gray;
}

.field-area-section--title {
  font-size: 1.55rem;
}

.field-area-section--data-holder {
  display: inline-flex;
  grid-gap: 4em;
  gap: 4em;
  flex-wrap: wrap;
}

.field-area-section--table,
.field-area-section--table th,
.field-area-section--table td {
  border: 1px solid black;
  border-collapse: collapse;
  height: 50px;
}

.field-area-section--table th:first-child,
.field-area-section--table td span:first-of-type {
  text-align: left;
  padding-left: 40px;
}
.field-area-section--table td span {
  font-weight: bold;
}
.field-area-section--table--header-text {
  padding-left: 10px;
  padding-right: 10px;
}
.field-area-section--table--field-name {
  min-width: 300px;
}
.field-area-section--table--field-name--inner {
  display: flex;
  align-items: center;
  justify-content: start;
}
.field-area-section--table--field-name--inner > span {
  padding-left: 10px !important;
}
.field-area-section--table--field-horses {
  text-align: center;
  font-weight: 600;
}
.field-area-section--table--field-button {
  min-width: 60px;
}
.field-area-section--table--field-button div {
  width: auto;
  display: flex;
  justify-content: center;
  margin: 5px;
}
.field-area-section--table--field-button img {
  height: 40px;
}

.field-btn {
  max-width: 200px;
  border-radius: 10px;
  margin-left: 5px;
  text-align: left;
  height: 50px;
}

.field-area-btn {
  max-width: 250px;
  border-radius: 10px;
  margin-left: 10px;
}

/* MODAL */
.field-manager--modal-text-input {
  border: none;
  border-bottom: 1px solid gray;
  outline: none;

  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .field-area-section {
    width: 100%;
    margin: 0;
  }
}

.field-manager--modal--holder {
  display: flex;
  flex-direction: column;
}

.tcDelButton--field-area {
  width: 35px;
  height: 35px;
}

.field-area-section--enforce-fields {
  width: 50%;
  display: flex;
  margin-left: auto;
  /* gap: 50px; */
}
.checkbox {
  accent-color: rgba(37, 64, 83, 1);
  margin-left: auto;
  margin-right: 10px;
}

.field-area-scrollable {
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}
.field-area-section--title-holder {
  display: flex;
  align-items: center;
}
.field-area-section--title-holder > h3 {
  margin-left: 10px;
}
.field-manager--scroll-area {
  max-height: 100vh;
  overflow-y: auto;
}
.field-manager--holder {
  margin-top: 50px;
}

.field-area-section {
  overflow-y: auto;
  padding: 10px;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0;
  border-bottom: 1px solid gray;
}

.field-area-section--title {
  font-size: 1.55rem;
}

.field-area-section--data-holder {
  display: inline-flex;
  grid-gap: 4em;
  gap: 4em;
  flex-wrap: wrap;
}

.field-area-section--table,
.field-area-section--table th,
.field-area-section--table td {
  border: 1px solid black;
  border-collapse: collapse;
  height: 50px;
}

.field-area-section--table th:first-child,
.field-area-section--table td span:first-of-type {
  text-align: left;
  padding-left: 40px;
}
.field-area-section--table td span {
  font-weight: bold;
}
.field-area-section--table--header-text {
  padding-left: 10px;
  padding-right: 10px;
}
.field-area-section--table--field-name {
  min-width: 300px;
}
.field-area-section--table--field-name--inner {
  display: flex;
  align-items: center;
  justify-content: start;
}
.field-area-section--table--field-name--inner > span {
  padding-left: 10px !important;
}
.field-area-section--table--field-horses {
  text-align: center;
  font-weight: 600;
}
.field-area-section--table--field-button {
  min-width: 60px;
}
.field-area-section--table--field-button div {
  width: auto;
  display: flex;
  justify-content: center;
  margin: 5px;
}
.field-area-section--table--field-button img {
  height: 40px;
}

.field-btn {
  max-width: 200px;
  border-radius: 10px;
  margin-left: 5px;
  text-align: left;
  height: 50px;
}

.field-area-btn {
  max-width: 250px;
  border-radius: 10px;
  margin-left: 10px;
}

/* MODAL */
.field-manager--modal-text-input {
  border: none;
  border-bottom: 1px solid gray;
  outline: none;

  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .field-area-section {
    width: 100%;
    margin: 0;
  }
}

.field-manager--modal--holder {
  display: flex;
  flex-direction: column;
}

.tcDelButton--field-area {
  width: 35px;
  height: 35px;
}

.field-area-section--enforce-fields {
  width: 50%;
  display: flex;
  margin-left: auto;
}
.checkbox {
  accent-color: rgba(37, 64, 83, 1);
  margin-left: auto;
  margin-right: 10px;
}

