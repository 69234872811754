#vaccinationEditWindowReact,
.editWindow {
  box-shadow: 2px 3px 5px #999;
  padding-bottom: 10px;
  z-index: 100 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vaccinationEditWindowReactTable {
  width: 100%;
  table-layout: fixed;
  border-spacing: 20px 0;
  margin-top: 10px;
}

.dateVaccinationDivReact {
  z-index: 1;
}

.dateVaccinationDivReact div {
  margin: 0px;
}

.dateVaccinationDivReact {
  background-color: transparent;
  color: white;
  text-shadow: none;
  cursor: pointer;
}

#vacAddTypeReact {
  background-color: red;
  width: 100%;
}
