.npContent {
    margin-top: 45px;
    padding: 10px;
}

.npTitle {
    color: white;
    font-size: 1.2em;
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.npButton {
    width: initial !important;
    margin-right: 10px;
}

.npDeleteIcon {
    width: 15px;
    height: 15px;
    margin-left: 15px;
}

.npTextarea {
    width: 100%;
    height: 250px !important;
}

.npAddIcon {
    height: 35px;
    cursor: pointer;
    margin-left: 5px;
}