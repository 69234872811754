.VaccinationTable {
  width: auto;
  border-collapse: collapse;
}

.VaccinationTable td {
  border: 1px solid gray;
  text-align: center;
  vertical-align: middle;
}
#vaccinationViewDiv {
  overflow-x: auto;
}
.VaccinationTable th {
  border: 1px solid gray;
}

.VaccinationTable tr > th {
  background-color: #292a3c;
  color: #ffffff;
  text-shadow: 0 0 0;
}

.remainingHorsesVaccinationTable td {
  text-align: center;
  vertical-align: middle;
}

.actionIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.reportVaccButton {
  margin-top: 6px;
  height: 46px;
}
